.stats {
  display: inline-block;
  margin-right: 3rem;

  .title {
    color: #6b778c;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 0px;
  }

  .value {
    font-size: 2rem;
    font-weight: bold;
  }

  .subtitle {
    color: #6b778c;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
  }
}