.tab {
  padding: 0;
  margin: 1rem 0 1rem 0;
  list-style: none;
  display: flex;
  border-bottom: 2px solid rgb(235, 236, 240);

  li {
    padding: 0.25rem 0 0.4rem 0;
    margin: 0 1rem 0 0;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    color: rgb(66, 82, 110);

    > a {
      color: inherit;
    }
  }

  li:last-child {
    margin-right: 0;
  }

  li.actions {
    flex-grow: 1;
    cursor: auto;

    > div {
      position: absolute;
      right: 0;
      bottom: 0.4rem;
    }
  }

  li.active,
  li:hover {
    color: rgb(0, 82, 204);
  }

  li.active:after {
    content: '';
    height: 2px;
    width: 100%;
    background: rgb(0, 82, 204);
    display: block;
    position: absolute;
    bottom: -2px;
  }
}
