.container {
  margin-bottom: 0.4rem;
}
.container:hover {
  opacity: 0.8;
  transition: all 0.2s ease;
}

.itemRow {
  display: flex;
  .img_wrap {
    width: 110px;
    img {
      width: 110px;
      height: auto;
      border-radius: 8px;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
    }
  }
  del {
    margin-left: 10px;
    color: #555;
  }

  .desc {
    position: relative;
    padding: 0 0 0 0.5rem;
  }

  .title {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 15px;
    line-height: 18px;
  }
  .sponsored {
    position: absolute;
    top: -19px;
    right: 0;
    padding: 0px 5px;
    color: #aaa;
    font-size: 11px;
    background-color: #fff;
  }

  .star {
    font-size: 12px;
    margin-left: 5px;
    color: #aaa;
  }
  .price_after_Discount {
    margin-right: 10px;
    font-size: 17px;
    line-height: 18px;
  }
  .is_Choice {
    position: static;
    top: 0;
    left: 0;
  }
  .badge {
    display: inline-block;
    margin: 6px 5px 5px 0;
    padding: 1px 12px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    box-shadow: 1px 1px 4px 1px rgba(#696969, 0.1);
    background: #ec0c0c;
  }
  .badge2 {
    display: inline-block;
    margin: 6px 5px 5px 0;
    padding: 1px 12px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    box-shadow: 1px 1px 4px 1px rgba(#696969, 0.1);
    background: #3b247a;
  }
  .discount_percentage {
    font-size: 12px;
    padding: 0 2px 0 0px;
    margin-left: 1px;
    line-height: 18px;
    color: #ff0037;
    border-radius: 3px;
    line-height: 18px;
    span {
      color: #aaa;
    }
  }
  .price_before_discount {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #aaa;
    text-decoration: line-through;
    line-height: 18px;
  }
  .stock_status {
    display: inline-block;
    font-size: 11px;
    line-height: 11px;
    color: #aaa;
    span {
      font-weight: 500;
    }
  }
  .product_label {
    font-size: 13px;
    color: #ff0037;
    font-weight: 500;
  }
  .star_count {
    display: block;
    line-height: 20px;
    margin: 0;
    .star_icon {
      margin-right: 2px;
      font-size: 15px;
      color: #ff0037;
      img {
        width: 11px;
      }
    }
    span {
      font-weight: 400;
      color: #aaa;
      font-size: 13px;
    }
  }
}

.item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  del {
    margin-left: 10px;
    color: #555;
  }

  img {
    width: 100%;
    height: auto;
  }

  .desc {
    position: relative;
    padding: 0 0.5rem 0.5rem 0.5rem;
  }

  .title {
    font-size: 12px;
    display: block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding: 1px;
  }

  // .....................topBadgeDiscount......................
  .discount_badge {
    position: absolute;
    top: 0;
    left: 0;
    span {
      padding: 1px 8px;
      color: #fff;
      border-radius: 6px;
      background: #ec0c0c;
      display: inline-block;
      font-weight: bold;
      font-size: 16px;
      box-shadow: 1px 1px 2px rgba(#696969, 0.4);
    }
  }
  .list_badge {
    display: flex;
    margin: 4px 0 0;
    .price {
      flex-grow: 1;
      font-size: 17px;
      line-height: 20px;
      font-weight: bold;
    }
    .is_Choice_Badge {
      height: 20px;
      span {
        display: block;
        margin-top: 1px;
        padding: 2px 9px 1px 9px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 6px;
        color: #fff;
        background: #ec0c0c;
        box-shadow: 1px 1px 4px 1px rgba(#696969, 0.1);
      }
    }
    .price_before {
      position: absolute;
      left: 8px;
      bottom: 8px;
      margin: 0;
      padding: 0;
      font-size: 13px;
      line-height: 11px;
      font-weight: bold;
      color: #818181;
      text-decoration: line-through;
    }
    .stock_status_badge {
      position: absolute;
      left: 8px;
      bottom: 8px;
      font-size: 11px;
      line-height: 11px;
      color: #5d5d5d;
      span {
        font-weight: bold;
      }
    }
  }
  // .....................topBadgeDiscount......................

  .sponsored {
    position: absolute;
    top: -19px;
    right: 0;
    padding: 0px 5px;
    color: #aaa;
    font-size: 11px;
    background-color: #fff;
  }
  .star {
    font-size: 12px;
    margin-left: 5px;
    color: #aaa;
  }
  .is_Choice {
    position: absolute;
    top: -26px;
    left: 8px;
  }
  .badge {
    display: inline-block;
    margin-right: 2px;
    padding: 1px 9px 1px 8px;
    color: #fff;
    border-radius: 6px;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 1px 1px 4px 1px rgba(#696969, 0.1);
    background: #ec0c0c;
  }
  .badge2 {
    display: inline-block;
    margin-right: 2px;
    padding: 1px 9px 1px 8px;
    border-radius: 6px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    box-shadow: 1px 1px 4px 1px rgba(#696969, 0.1);
    background: #3b247a;
  }
  .price_after_Discount {
    flex-grow: 1;
    font-size: 17px;
    line-height: 18px;
  }
  .discount_percentage {
    font-size: 12px;
    padding: 0 2px 0 0px;
    margin-left: 1px;
    line-height: 18px;
    color: #ff0037;
    // background-color: #ffe4e4;
    border-radius: 3px;
    line-height: 18px;
    span {
      color: #aaa;
    }
  }
  .price_before_discount {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #aaa;
    text-decoration: line-through;
    line-height: 18px;
  }
  .stock_status {
    position: absolute;
    left: 8px;
    bottom: 8px;
    display: inline-block;
    font-size: 11px;
    line-height: 11px;
    color: #aaa;
    span {
      font-weight: 500;
    }
  }
  .star_count {
    position: absolute;
    bottom: 8px;
    right: 8px;
    line-height: 0;
    margin: 2px 0;
    .star_icon {
      margin-right: 2px;
      font-size: 15px;
      color: #ff0037;
      img {
        width: 11px;
      }
    }
    span {
      font-weight: 400;
      color: #aaa;
      font-size: 13px;
    }
  }

  .product_label {
    font-size: 13px;
    font-weight: 500;
    color: #ff0037;
  }
}

.img_load {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .img_loading {
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    text-align: center;
    scroll-snap-align: center;
    align-content: center;
    align-items: center;
  }
}
