.markup-container {
  display: flex;
  margin-top: -54px;
  padding-top: 54px;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
}

.markup-header {
  padding: 1rem;
  background: #fff;
  border-bottom: 2px solid #f1f1f1;
}

.markup-editor {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .preview {
    width: 350px;
    flex-shrink: 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      height: 100%;

      li.add-new {
        background: #2ecc71;
        color: #fff;

        i {
          margin-right: 0.5rem;
        }
      }

      li.add-new:hover {
        background: #27ae60;
      }

      li {
        padding: 15px;
        cursor: pointer;
        margin: 0.5rem;
        border-radius: 8px;
        background: #fff;
      }

      li.active, li.active:hover {
        background: #0984e3;
        color: #fff;
        box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
      }

      li:hover {
        background: #eee;
      }
    }
  }

  .options {
    background: #fff;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }

  .type-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    flex-wrap: wrap;

    li {
      box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
      padding: 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      width: 150px;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      border-radius: 6px;
    }

    li.active, li.active:hover {
      background: #0984e3;
      color: #fff;
    }

    li:hover {
      background: #f1f1f1;
    }
  }
}

.markup-tab-container {
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  border: 1px solid #f1f1f1;
  border-radius: 6px;

  .nav-link {
    i {
      font-size: 1rem;
    }
  }

  .os-tabs-controls {
    margin: 0;
  }

  .markup-tab-body {
    padding: 1rem;
  }
}