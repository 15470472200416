.table {
  width: 100%;
  position: relative;

  thead {
    border-bottom: 2px solid #fbc531;
  }

  tbody {
    border-bottom: 2px solid rgb(235, 236, 240);
  }

  th {
    background: #f5f6fa;
  }

  td,
  th {
    padding: 0.5rem 1rem;

    strong {
      font-weight: 700;
    }
  }

  tr {
    background: #fff;
  }

  .template_info {
    a {
      font-weight: 600;
      color: rgb(66, 82, 110);
    }

    small {
      display: block;
      color: rgb(151, 160, 175);
      margin-top: 0.25rem;
    }
  }

  .grow {
    width: 100%;
  }
}

.table.border {
  //  overflow: hidden;
  box-shadow: 0 2px 3px rgba(3, 27, 78, 0.1);
  border-top: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
   border-radius: 8px;
  

}

.table.stripe {
  tbody {
    tr:nth-child(odd) {
      background: #f1f2f6;
    }
  }
}

.seperator {
  tr:not(:last-child) {
    td {
      border-bottom: 1px solid #eee !important;
    }
  }
}

.grid {
  td,
  th {
    border: 1px solid #eee !important;
  }
}

.sticky {
  th {
    position: sticky;
    z-index: 99;
    top: 0;
  }
}
