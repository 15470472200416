.modal_container {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  z-index: 1000;

  .content {
    padding: 10px;
    min-width: 300px;
  }

  .header {
    padding: 10px;
    background: #ecf0f1;

    h5 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }

  .footer {
    border-top: 1px solid #ecf0f1;
    padding: 10px;
    text-align: right;

    a,
    button {
      margin-left: 10px;
    }
  }
}
