.container {
  min-height: 100vh;
  column-count: 2;
  -webkit-column-count: 2;
  column-gap: 0.4rem;
  padding: 0.6rem;
  background: #fff;
}
.header {
  div {
    height: 38px;
    width: 100%;
    background: #f5f5f5;
  }
}
.item {
  .item_inner {
    height: 300px;
    .img {
      background: #f5f5f5;
      height: 245px;
      margin-bottom: 6px;
      border-radius: 12px;
    }
    .title {
      background: #f5f5f5;
      height: 20px;
      margin-bottom: 5px;
    }
    .txt {
      background: #f5f5f5;
      width: 80%;
      height: 15px;
    }
  }
}
