.overlay-contain {
  width: 1rem;
  background-color: transparent;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.overlay-contain > .main {
  position: relative;
  overflow-x: hidden;
  background-color: rgba(235, 235, 235, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 2rem;
  max-height: 90vh;
  overflow-y: auto;
}

.overlay-contain:hover {
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: 240px;
}
