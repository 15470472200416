input.lf-form-control,
select.lf-form-control,
textarea.lf-form-control,
div.lf-form-control {
  padding: 0.5rem;
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 0;
  background: #f1f1f1;
  -webkit-appearance: none;
  min-width: 30px;
}

.control-header {
  input,
  select {
    background: #fff;
  }
}

.lf-form-control.lf-inline {
  width: 150px;
}

.lf-input-group {
  display: flex;
  flex-wrap: nowrap;

  > input.lf-form-control,
  > select.lf-form-control,
  > textarea.lf-form-control,
  > div.lf-form-control {
    flex: 1 1 auto;
    width: auto;
  }

  .lf-form-control:not(:first-child),
  .lf-btn:not(:first-child) {
    border-left: 2px solid #fff;
  }
}

select.lf-form-control {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjDAEAIAZdr8h/AAAAaUlEQVQoz73RsQ3CQAyF4a85MUlmSpukgUEyz1VMk10uTgEIHRjKvNL/L1n244QMViUlxWpgE2qiFFXYWLREeeBmgVkT7i4feHd7DXrlC/dKit9Kfe6es7MmTQi766+fTNo/DKPxjHa6HK6QLW1XChb6AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTEyLTAxVDAwOjMyOjA2KzAwOjAwlW4yLwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0xMi0wMVQwMDozMjowNiswMDowMOQzipMAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #f1f1f1;
  padding-right: 30px;
}

.lf-btn {
  padding: 0.5rem 1rem;
  border: 0;
  cursor: pointer;
}

.lf-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
  }

  :hover ~ :before {
    background-color: #ccc;
  }

  input:checked ~ :before {
    content: '\2713';
    display: block;
    color: #fff;
    background-color: #047bf8;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.dropdown-category-item {
  cursor: pointer;
}

.dropdown-category-item.selected,
.dropdown-category-item.selected:hover {
  background: #3498db;
  color: #fff;
}

.dropdown-category-item:hover {
  background: #f1f1f1;
  color: #000;
}
