.alert-small-left {
  position: fixed !important;
  z-index: 1051 !important;
  right: 0;
  transform: translateX(10%);
  -webkit-box-shadow: 0px 0px 3px 0px #7A7A7A; 
  box-shadow: 0px 0px 3px 0px #7A7A7A;
  animation: slide-left .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.alert-small-right {
  position: fixed !important;
  z-index: 1051 !important;
  right: 0;
  transform: translateX(10%);
  -webkit-box-shadow: 0px 0px 3px 0px #7A7A7A; 
  box-shadow: 0px 0px 3px 0px #7A7A7A;
  animation: slide-right .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.more-messages {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.more-messages:hover() {
  color: #2980b9;
  text-decoration: underline;
}

// .activity-box {
//   transition: all .3s;
// }

// .activity-box:hover{
//   box-shadow: 0px 5px 7px rgba(69, 101, 173, 0.1)
// }

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
  100% {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
  100% {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button { 
  display: none;
}

.table.table-editable td:hover {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 0px #047bf8;
}