.msg_wrap {
  position: relative;
  z-index: 100000;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  .content {
    display: flex;
    flex-wrap: wrap;
    min-width: 330px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #01bd9e;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    .content_inner {
      position: relative;
      display: flex;
      height: 100%;
      display: flex;
      align-items: center;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      .icon_bell {
        width: 35px;
      }
      .msg {
        h5 {
          font-size: 0.975rem;
          line-height: 15px;
          color: #fff;
        }
        span {
          margin: 0;
          padding: 0;
          font-size: 0.875rem;
          color: #fff;
          line-height: 15px;
          text-transform: capitalize;
        }
      }
      .removeBtn {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 15px;
        height: 15px;
        line-height: 15px;
        text-align: center;
      }
    }
  }
}
