div.side-menu {
  display: none;
}

.side-menu-logo {
  margin: 0.25rem 0 0.8rem;
  font-size: 2.1rem;
  font-weight: bold;
  color: #eb3b5a;
  cursor: pointer;
  text-align: center;
}

.side-menu-icon {
  width: 30px;
}

@media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  div.side-menu {
    width: 250px;
    min-width: 225px;
    flex-shrink: 0;
    display: block;
    position: relative;

    div.side-menu-content {
      position: fixed;
      width: 248px;
      top: 0;

      background: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      clip-path: inset(0px -5px 0px 0px);
      padding: 0 0.5rem 0.5rem 0.5rem;
      overflow-y: auto;
      height: 100vh;

      &::-webkit-scrollbar-track {
        background-color: #d1d8e0;
      }

      &::-webkit-scrollbar {
        background-color: #a5b1c2;
        width: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #4b6584;
      }
    }

    h3 {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #eb3b5a;
      margin: 1rem 0;
      padding: 4px 5px 5px 5px;
      border-radius: 4px;
      background-color: rgba(237, 237, 237, 0.4);
    }
  }

  .side-menu-level-1 {
    padding: 0.25rem 0.5rem 0.25rem 1rem;
    margin-top: 2px;
    margin-bottom: 2px;
    font-weight: 600;
    display: flex;
    line-height: 1.2rem;
    color: inherit;
    text-decoration: none;

    &:hover {
      background-color: #1b55e2;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
    }

    &.selected {
      color: #fff;
      // background: #778ca3;
      background-color: #1b55e2;
      border-radius: 4px;
    }

    i,
    a,
    span {
      color: inherit;
      display: block;
      line-height: 1.2rem;
      text-decoration: none;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: 0;
    }

    a,
    span {
      flex-grow: 1;
      cursor: pointer;
    }
  }

  ul.side-menu-level-2 {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      padding: 0.2rem 0.2rem 0.1rem 2.25rem;
      margin-top: 2px;
      margin-bottom: 2px;
      color: #000;
      a {
        display: block;
        color: inherit;
        text-decoration: none;
      }

      a:hover {
        text-decoration: none;
      }

      &.selected {
        color: #fff;
        background-color: #1b55e2;
        border-radius: 4px;
      }
    }

    li:hover {
      background-color: #3d74ff;
      border-radius: 4px;
    }
    li:hover > a {
      color: #fff;
    }
  }
}
