.suggested_wrap {
  height: 80px;
  background-color: #fff;
  touch-action: manipulation;
  .suggested_inner {
    .suggested_list {
      overflow: scroll;
      display: flex;
      width: 100%;
      height: 80px;
      margin: 0;
      padding: 0;
      align-items: center;
      text-align: center;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      li {
        list-style: none;
        div {
          width: 70px;
          margin: 0 5px;
          span {
            overflow: hidden;
            display: inline-flex;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid #edeef0;
            vertical-align: bottom;
            img {
              width: 50px;
            }
            strong {
              display: block;
              width: 100%;
              line-height: 50px;
              color: #b1b1b1;
              font-size: 1.1rem;
            }
          }

          p {
            overflow: hidden;
            display: block;
            display: -webkit-box;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 0.7rem;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
      li:hover {
        span {
          border: 1px solid #e74c3c;
        }
      }
      li:visited {
        span {
          border: 1px solid #e74c3c;
        }
      }
    }
  }
}
.suggested_list::-webkit-scrollbar {
  display: none;
}
