nav.quick {
  box-shadow: 0px 1.2px 0px rgba(91, 91, 91, 0.2);
  box-sizing: border-box;
  background: #fff;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;

    li {
      border-right: 1px solid #f1f1f1;

      i.fas,
      i.far,
      i.fal {
        display: inline-block;
        font-size: 1.5rem;
        margin-right: 10px;
        opacity: 0.8;
      }

      a {
        padding: 1rem;
        display: flex;
        text-decoration: none;
        color: inherit;
      }

      a:hover {
        background: #555;
        color: #fff;
      }
    }
  }
}

span.link {
  color: #047bf8;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
