.dashboard {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  max-width: 800px;;
}

.dashboard-entry {
  background: #f1f1f1;
  padding: 0 10px;
  border-radius: 4px;
  color: #555;
}

.dashboard-entry:hover {
  text-decoration: none;
}

.dashboard-entry.small {
  grid-column: auto / span 2;
  height: 35px;
  line-height: 35px;
  font-size: 14px;

  i {
    margin-right: 10px;
  }
}

.dashboard-entry.big {
  height: 90px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  i {
    font-size: 25px;
    display: block;
    margin-bottom: 10px;
  }

  span {
    display: block;
  }
}

@media screen and (min-width: 500px) {
  .dashboard {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-template-rows: repeat(6, 35px);
  }

  .dashboard-entry.big {
    height: auto;
    grid-row: auto / span 3;
  }

  .dashboard-entry.small {
    grid-row: auto;
    grid-column: auto;
  }
}

@media screen and (min-width: 800px) {
  .dashboard {
    grid-template-columns: 150px 150px 1fr 1fr;
    grid-template-rows: repeat(3, 35px);
  }
}
