.l192-card {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all .5s;

  .title {
    margin: 0 0 10px 0;
    font-weight: bold;
    font-size: 15pt;
    text-transform: capitalize;
  }

  .description {
    margin: 0;
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
}

.l192-dropbox {
  background-color: #ffffff;
  padding: 0px;
  border-radius: 5px;
  list-style: none;
  margin-top: 10px;

  li{
    font-size: 12pt;
    border-width: 0 0 .5px 0;
    border-style: solid;
    border-color: rgba(0,0,0,0.2);
    padding: 10px;
  }

}

.l192-card:hover {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.26);
}