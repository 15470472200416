.package_search_wrap {
  position: relative;
  .package_search_wrap_inner {
    display: flex;
    height: 35px;
    margin-top: 2px;
    line-height: 100%;
    .package_type_selected {
      border: 1px solid #dee2e6;
      border-right: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #f5f6f8;
      z-index: 999;
      .package_type_selected_txt {
        display: flex;
        cursor: pointer;
        span {
          flex-grow: 1;
          display: inline-block;
          padding: 0 10px 0 15px;
          line-height: 38px;
          color: #666f7c;
        }
        i {
          padding: 0 6px;
          line-height: 36px;
        }
      }
      .drpdwn {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 42px;
        margin-top: -2px;
        padding: 0;
        background-color: #f5f6f8;
        box-shadow: 1px 2px 2px rgba(91, 91, 91, 0.4);
        li {
          padding: 10px 15px;
          list-style: none;
          font-size: 14px;
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
    input {
      width: 300px;
      padding: 0 15px;
      border: 1px solid #dee2e6;
      outline: none;
      border: none;
      border: 1px solid #dee2e6;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      line-height: 25px;
    }
    .loading {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    button {
      margin: 0;
      padding: 0 15px 0 15px;
      border: none;
      border: 1px solid #dee2e6;
      border-left: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      font-weight: bold;
      cursor: pointer;
      background-color: #0052cc;
      color: #fff;
      line-height: 30px;
    }
  }
}
