.menu_list_wrap {
  width: 100%;
  background-color: #fff;
  ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    margin: 2rem 0;
    padding: 0;
    li {
      position: relative;
      width: 25%;
      height: 100px;
      margin-bottom: 30px;
      list-style: none;
      text-align: center;
      cursor: pointer;
      > div::before {
        content: '';
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 12px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../../../public/assets/bg.svg');
        // clip-path: polygon(18% 53%, 84% 53%, 100% 100%, 0% 100%);
        // background: linear-gradient(
        //   180deg,
        //   rgba(255, 255, 255, 0) 10%,
        //   rgba(246, 246, 246, 0.7819502801120448) 35%,
        //   rgba(236, 236, 236, 1) 100%
        // );
      }
      > div {
        position: relative;
        cursor: pointer;
        span {
          display: block;
        }
      }
      .img_wrap {
        position: relative;

        width: min-content;
        height: 70px;
        margin: 0 auto;
        border-radius: 12px;
        // background-color: #d6d6d6;
      }
      img {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        display: block;
        width: auto;
        height: 70px;
        margin: 0 auto;
      }

      p {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 0.875rem;
        font-weight: bold;
        z-index: 2;
      }
    }
  }
}
.prview {
  position: absolute;
  bottom: 6rem;
  right: 2rem;
  margin: auto 0 auto auto;
  border: 12px solid #000;
  background-color: #000;
  border-radius: 40px;
  z-index: 100;
  .overlay {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0.775rem;
    top: 2.5rem;
    background-color: transparent;
  }
  .ic_badge {
    position: absolute;
    top: -40px;
    right: 10px;
    width: 40px;
    padding: 8px 0;
    cursor: pointer;
    text-align: center;
    color: #fff;
    background-color: #000;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    z-index: 10;
  }
  .prview_inner {
    overflow: hidden;
    width: 360px;
    height: 740px;
    max-width: 100%;
    // padding: 0.8rem;
    word-break: break-all;
    border-radius: 34px;
    background-color: #fff;
  }
}
.gradient_active {
  position: relative;
  animation: fadeIn 0.5s;
  z-index: 20;
  span::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    background: linear-gradient(
      120deg,
      #fb0094,
      #134aff,
      #00c3ff,
      #00ff00,
      #ffff00,
      #ff0000,
      #fb0094,
      #00c3ff,
      #00ff00,
      #ffff00,
      #ff0000
    );
    background-size: 300% 300%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
    border-radius: 17px;
    opacity: 1;
    z-index: -1;
  }
}
.gradient_active p {
  background: linear-gradient(120deg, #ffe032, #ff6868, #ff0000, #ffe032, #ff6868, #ff0000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 250% 250%;

  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 9%;
  }
  50% {
    background-position: 100% 93%;
  }
  100% {
    background-position: 0% 9%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
