.tag {
  position: relative;

  .suggest {
    position: absolute;
    list-style: none;
    background-color: #ffffff;
    padding: 0;
    width: 95%;
    border-radius: 5px;
    border: solid 1px #4771d2;
    margin-top: 5px;
    z-index: 10;

    li {
      padding: 10px;
      cursor: pointer;
    }

    li:hover {
      background-color: #5897fb;
      color: #ffffff;
    }

  }

  .inline-tag {
    display: flex;
  }

  .tags {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    border: 0;

    li {
      background-color: #e2ebff;
      border: solid 1px #4771d2;
      border-radius: 4px;
      padding: 0 5px;
      margin:  0 5px;

      .remove {
        margin-right: 4px;
        cursor: pointer;
      }

    }

  }

  input {
    border: none;
  }

  input:focus {
    outline-offset: 0px;
  }

  :focus {
    outline: -webkit-focus-ring-color auto 0px;
  }

}