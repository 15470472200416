.pag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 7px 9px 7px 13px;

  .label {
    font-weight: 550;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .pag_button {
    cursor: pointer;
    background: none;
    border: none;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    text-decoration: none;

    &:hover {
      background: #d4d4d4;
      border-radius: 0.3rem;
    }
  }

  .pag_button:disabled,
  .pag_button[disabled],
  .pag_button[aria-disabled='true'] {
    cursor: auto;
    background: none;
    border: none;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    text-decoration: none;

    &:hover {
      border-radius: 0.3rem;
    }
  }
}

.tree_item {
  display: flex;
  justify-content: space-between;
  margin-top: 0.04125rem;
  margin-bottom: 0.04125rem;

  .active {
    background-color: rgba(235, 235, 235, 1);
  }

  div:first-child {
    padding: 0.5rem;
    width: 80%;
    border-radius: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;

    .text {
      width: 90%;
      max-width: 250px;
      padding: 0;
      font-size: 9pt;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: rgba(241, 241, 241, 1);
    }
  }

  div:last-child {
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(241, 241, 241, 1);
    }
  }
}

.tree_item_reverse {
  display: flex;
  justify-content: space-between;
  margin-top: 0.04125rem;
  margin-bottom: 0.04125rem;

  .active {
    background-color: rgba(235, 235, 235, 1);
  }

  div:last-child {
    padding: 0.5rem;
    width: 80%;
    border-radius: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;

    .text {
      width: 90%;
      max-width: 250px;
      padding: 0;
      font-size: 9pt;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: rgba(241, 241, 241, 1);
    }
  }

  div:first-child {
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(241, 241, 241, 1);
    }
  }
}

.tree_item_loading {
  div {
    padding: 0.5rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: #005bd3;

    &:hover {
      background-color: rgba(241, 241, 241, 1);
    }
  }
}
