table.lf-table-shadow {
  background: #fff;
  width: 100%;
  border-radius: 5px;

  td, th {
    padding: 0.75rem;
    border-bottom: 1px solid #eee;
  }
}

.lf-table-scrollable {
  height: 200px;
  overflow: auto;
  overflow-x: hidden;

  table {
    border: 0 !important;
  }

  thead tr th {
    background: #555;
    color: #fff;
    border: 0 !important;
    position: sticky;
    top: 0;
  }
}