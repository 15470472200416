.double-toggle {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;

  &:hover {
    background-color: rgba(241, 241, 241, 1);
  }
}
