.text_inline {
  display: flex;

  .text_object {
    width: 1rem;
  }

  .text_dot {
    width: 0.5rem;

    &::before {
      content: '︰';
    }
  }

  .text_value {
    width: 9rem;
  }
}

.text_truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.border_right_width {
  border: solid;
  border-width: 0 0.0625rem 0 0;
  border-color: rgba(225, 227, 229, 1);
  padding: 0 0.75rem 0 0.75rem !important;
}

.width_fixed {
  width: 150px !important;
}

.border_bottom_width {
  border: solid;
  border-width: 0 0 0.0625rem 0;
  border-color: rgba(225, 227, 229, 1);
  padding: 0 0.75rem 0 0.75rem !important;
}

.table_cell_top {
  vertical-align: top;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #f3f3f3;
    text-align: center;
    padding: 0;
    border-radius: 6px;
    position: absolute;
    z-index: 9999;
    top: -5px;
    left: 105%;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}
