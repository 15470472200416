.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #f5f5f5;
  padding: 0.4rem;
}
.containerRow {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #fff;
  padding: 0.4rem;
}

.list_container {
  width: 50%;
  padding: 0.2rem 0.2rem 0px 0.2rem;
}

ul.filter_Wrap {
  // position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  margin: 0;
  padding-left: 0.6rem;
  border-top: 1px solid #edeef0;
  border-bottom: 1px solid #edeef0;
  background-color: #fff;
  z-index: 9999;
  li:first-child {
    // flex-grow: 1;
    border-left: none;
    margin-right: 0;
    min-width: 64px;
  }
  li {
    align-content: center;
    display: flex;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.85rem;
    div {
      line-height: 48px;
    }
    p {
      margin: 0;
      line-height: 38px;
    }
    svg {
      margin-top: 7px;
      margin-left: 5px;
    }
  }
  li.list {
    padding-right: 0.6rem;
    p {
      padding: 0 0 0 5px;
    }
    .btn_list {
      border-right: 1px solid #edeef0;
      // margin: 6px 0;
      padding-right: 10px;
      line-height: 40px;
    }
  }
  .btn_Sort {
    flex-grow: 1;
    padding: 0;
    cursor: pointer;

    div {
      display: flex;
      text-decoration: none;
      line-height: 40px;
      outline: none;
      svg {
        margin-left: 0;
        margin-top: 18px;
      }
    }
  }
}
.drpSort {
  position: relative;
  cursor: default;
  p::first-letter {
    text-transform: uppercase;
  }
  img {
    margin-left: 5px;
    margin-bottom: 1px;
  }
  .drpSort_Layer {
    position: absolute;
    top: 39px;
    right: 0;
    bottom: 0;
    left: -0.6rem;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    .drpSort_Layer_inner {
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid #edeef0;
    }
    ul {
      display: block;
      width: 100vw;
      margin: 0;
      padding: 0;
      background-color: #fff;
      li {
        display: block;
        padding: 0 0.675rem;
        border-top: 1px solid #edeef0;
      }
      li.active {
        p {
          color: #ee6d7a;
          background-repeat: no-repeat;
          background-size: 16px;
          background-position: 100% 13px;
        }
      }
    }
  }
}
.data_empty {
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  p {
    margin: 0;
    padding: 1rem 0.6rem 0 0.6rem;
    font-size: 0.875rem;
    text-align: left;
  }
}
.list_container_row {
  width: 100%;
  padding: 0.2rem 0.2rem 0px 0.2rem;
}

.header {
  div {
    height: 40px;
    width: 100%;
    background: #f5f5f5;
  }
}

.containerRow {
  min-height: 100vh;
  padding: 0.6rem;
  background: #fff;
}
.itemRow {
  .itemRow_inner {
    display: flex;
    margin: 0.6rem 0;
    .img {
      width: 110px;
      height: 160px;
      background: #f5f5f5;
    }
    .contentWrap {
      margin-left: 0.6rem;

      flex-grow: 1;
      .title {
        width: 90%;
        margin-bottom: 0.5rem;
        height: 20px;
        background: #f5f5f5;
      }
      .txt {
        width: 75%;
        height: 17px;
        margin-bottom: 0.6rem;
        background: #f5f5f5;
      }
      .txt2 {
        width: 40%;
        height: 20px;
        margin-bottom: 0.6rem;
        background: #f5f5f5;
      }
      .txt3 {
        width: 10%;
        height: 15px;
        margin-bottom: 0.6rem;
        background: #f5f5f5;
      }
      .txt4 {
        width: 15%;
        height: 15px;
        margin-bottom: 0.6rem;
        background: #f5f5f5;
      }
    }
  }
}
.checkbox {
  display: flex;
  padding-left: 1rem;
  .inputCheckWrap {
    line-height: 45px;
    padding-left: 0.3rem;
  }
  .inputCheckWrap input[type='checkbox'] {
    display: none;
  }
  .inputCheckWrap input[type='checkbox']:checked + .wrapper {
    background-color: #ee6d7a;
  }
  .inputCheckWrap input[type='checkbox']:checked + .wrapper .knob {
    left: 20px;
  }
  .inputCheckWrap .wrapper {
    background-color: #666;
    border: 1px solid #edeef0;
    border-radius: 10px;
    width: 42px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    outline: none;
  }
  .inputCheckWrap .knob {
    background-color: white;
    border: 1px solid #edeef0;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
    position: relative;
    width: 16px;
    height: 16px;
    left: 0;
    transition: left 100ms ease-in-out 0s;
  }
}
.wrap {
  width: 460px;
  margin: 0 auto;
  padding: 1rem 1rem 2rem 1rem;
  border: 1px solid #e2e2e3;
  border-radius: 12px;
}

.search_wrap {
  width: 385px;
  margin: 0 auto;
  padding: 0;
  span {
    position: relative;
    display: block;
    width: 100%;
    height: 38px;
    border: 1px solid #e2e2e3;
    border-radius: 8px;
    box-sizing: border-box;
    input[type='search'] {
      width: 100%;
      padding: 0 30px 0 15px;
      font-size: 16px;
      line-height: 38px;
      text-decoration: none;
      border: none;
      box-sizing: border-box;
      background-color: transparent;
      outline: none;
    }
    i {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.preview_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview_wrap_inner {
  position: relative;
  overflow-y: scroll;
  width: 370px;
  margin: 1rem auto 0 auto;
  min-height: 700px;
  height: 750px;
  border: 15px solid #222;
  border-radius: 32px;
  background-color: #222;
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.5);
}

.preview_wrap_content {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  border-radius: 20px;
}
.preview_wrap_inner::-webkit-scrollbar-track {
  position: absolute;
  border-radius: 6px;
}

.preview_wrap_inner::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 6px;
  width: 0px;
}

.preview_wrap_inner::-webkit-scrollbar-thumb {
  background-color: #737b84;
  border-radius: 6px;
}
