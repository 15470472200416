.thumbnail_info_wrap {
  width: 600px;
  height: 100vh;
  margin: 10rem auto 0 auto;

  .thumbnail_info_wrap_inner {
    padding: 1.5rem;
    border: 1px solid #ededed;
    border-radius: 8px;
    background-color: #fff;
    h5 {
      font-weight: bold;
      font-size: 20px;
      color: #6b6b6b;
    }
    > p {
      font-size: 16px;
      color: #6b6b6b;
    }
    .avatar {
      overflow: hidden;
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 2px solid #fff;
      span {
        img {
          width: 100%;
        }
      }
    }
    .thumbnail {
      position: relative;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .thumbnail_saving_point {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      padding: 0.5rem 1rem;
      color: #fff;
      background-color: #616161;
    }
    .lucky_draw_wrap {
      .lucky_draw_wrap_inner {
        padding: 1rem;
        border-radius: 8px;
        background-color: #e3ac5c;
        .lucky_draw {
          ul {
            display: flex;
            justify-content: space-around;
            padding: 0;
            li {
              display: flex;
              img {
                width: 35px;
                height: 35px;
              }
            }
          }
        }
        .lucky_draw_random_area {
          display: flex;
          height: 60px;
          justify-content: center;
          align-items: center;
          .lucky_draw_random_area_title {
            width: 150px;
            flex-grow: 1;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
          }
          .lucky_draw_random_area_input {
            overflow: hidden;
            position: relative;
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            ul {
              scroll-behavior: smooth;
              position: absolute;
              width: 100%;
              height: 40px;
              // margin: 15px 0;
              padding: 0;
              list-style: none;
              li {
                width: 100%;
                font-size: 1.7rem;
                font-weight: bold;
                line-height: 40px;
                background-color: #fff;
              }
            }
            .winner {
              position: absolute;
              width: 100%;
              height: 40px;
              line-height: 40px;
              left: 0;
              z-index: 5;
              font-size: 1.7rem;
              font-weight: bold;
              line-height: 40px;
              background-color: #fff;
            }
          }
        }
      }
    }
    .lucky_draw_footer {
      display: flex;
      width: 100%;
      margin-top: 1rem;
      :first-child {
        flex-grow: 1;
      }
      .notification {
        button {
          border: none;
          cursor: pointer;
          background-color: transparent;
        }
      }
      .btn_wrap {
        text-align: right;
        button {
          padding: 0.5rem;
          width: 200px;
          font-size: 20px;
          font-weight: bold;
          border-radius: 8px;
          background-color: #e3ac5c;
          border: none;

          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
