.bg-loading {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #f5f5f5 8%, #f0f0f0 38%, #f5f5f5 54%);
  background-size: 1000px 640px;

  position: relative;
}

.ph-big {
  height: 50px;
}

.ph-text-big {
  height: 30px;
  width: 100px;
  margin-bottom: 10px;
}

.ph-text-normal {
  height: 15px;
  width: 200px;
  margin-bottom: 5px;
}

.ph-text-full {
  width: 100%;
  height: 15px;
  margin-bottom: 5px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
