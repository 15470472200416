hr {
  margin: 0.5rem 0;
}
.task {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 8px;
  border-right: 1px solid #e2e4e6;
  border-left: 1px solid #e2e4e6;
  box-shadow: 0px 1.2px 0px rgba(91, 91, 91, 0.2);
  background-color: #fff;
  box-sizing: border-box;
  animation-name: fadeLeft;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  button,
  a {
    margin: 6px 0;
  }
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.task:last-child {
  margin-bottom: 0;
}

.task .content {
  padding-left: 10px;
}

.task .status {
  width: 30px;
  text-align: center;
}

.task .status i {
  font-size: 18px;
}
.notesBox {
  margin-top: 5px;
  padding: 0 5px;
  word-break: break-word;
  border: 1px solid #efd248;
  border-radius: 4px;
  background-color: #fff;
  i {
    font-size: 0.8rem;
  }
}
legend {
  margin-bottom: 0.5rem;
}

.btn_filter_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  button {
    margin: 3px 6px 3px 0px;
  }
}

.CheckboxWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    margin: 3px 6px 3px 0px;
  }
  div.CheckboxWrapCheck {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 15px;
    box-sizing: border-box;
    color: #fff;
    box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
    .boxColor {
      position: absolute;
      top: 0px;
      left: 28px;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 5px;
      z-index: 0;
    }
    label {
      margin-bottom: 5px;
      span {
        left: 0;
        padding: 0 15px 0 2px;
        line-height: 15px;
        vertical-align: bottom;
        z-index: 999;
        font-size: 0.695rem;
        font-weight: 600;
      }
      span:before {
        top: 3px;
        left: 3px;
        border-radius: 50%;
        z-index: 1;
        border: 1px solid #c7c8ca;
      }
    }
  }
}

.badge_wrap {
  display: flex;
  div {
    span {
      font-size: 12px;
      display: inline-block;
      margin-right: 6px;
      padding: 2px 10px;
      border-radius: 2px;
      box-shadow: 0 2px 4px rgba(3, 27, 78, 0.08);
      color: #fff;
    }
  }
}

.sellerWrap {
  display: flex;
  ul {
    margin: 0;
    padding: 0 0 0 20px;
    li {
      list-style: none;
      line-height: 20px;
      a {
        display: inline-block;
        padding-bottom: 4px;
        font-weight: 600;
        color: #42526e;
        font-size: 0.975rem;
      }
      span {
        color: #97a0af;
        font-size: 0.875rem;
      }
    }
  }
}
.mapAdress_wrap {
  .mapAdress {
    margin-bottom: 0.3rem;
    font-size: 14px;
    font-weight: bold;
    span {
      font-weight: 500;
      padding-left: 5px;
      color: #353638;
      font-size: 95%;
    }
  }
}
.current_success {
  z-index: 1000 !important;
}
.pin {
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10000;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 5px rgba(3, 27, 78, 0.3);
  border-radius: 6px;

  small {
    display: block;
  }
}

.group_by_hub_wrap {
  width: 100%;
  > ul {
    display: flex;
    padding: 10px 0;

    border: 1px solid #f1f1f1;
    > li:first-child {
      border-left: none;
    }
    > li {
      flex-grow: 1;
      padding: 0 10px;
      text-decoration: none;
      list-style: none;
      border-left: 1px solid #f1f1f1;
      ul {
        padding: 0;
        li {
          padding: 0;
          text-decoration: none;
          list-style: none;
        }
      }
    }
    button {
      margin: 2px 0;
    }
  }
}
