/*
 #6. Tabs Controls
*/

.os-tabs-controls {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;

  .nav-pills {
    align-items: center;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  }
  .nav-tabs {
    flex: 1;
  }

  .nav-link {
    cursor: pointer;
  }

  &.os-tabs-complex {
    .nav-tabs {
      align-items: stretch;
    }
    .nav-item {
      white-space: nowrap;
      margin-right: 0px;

      &.nav-actions {
        margin-left: auto;
      }
      .btn {
        margin-left: 10px;
      }
      .nav-link {
        color: $body-color;
        opacity: 0.6;
        border-right: 1px solid rgba(0,0,0,0.05);
        border-radius: 0px;
        padding: 8px 20px;
        margin-right: 0px;

        span {
          display: inline-block;
          vertical-align: middle;
          font-size: $font-size-base;
        }
        span.badge {
          font-size: $font-size-base * 0.8;
          padding: 3px 5px;
          span {
            font-size: $font-size-base * 0.8;
          }
        }
        &.active {
          opacity: 1;
          .tab-label {
            font-size: $font-size-base * 1.4;
            line-height: 1.25;
          }
        }
        .tab-label {
          display: inline-block;
          margin-right: 10px;
          text-transform: none;
          letter-spacing: normal;
          font-size: $font-size-base * 1.2;
          vertical-align: middle;
          transition: all 0.2s ease;
        }
        .tab-value {
          display: inline-block;
          color: $primary;
          margin-right: 10px;
          text-transform: none;
          letter-spacing: normal;
          vertical-align: middle;
        }
        .badge {
          display: inline-block;
          vertical-align: middle;
          font-size: $font-size-base * 0.8;
          letter-spacing: normal;
          i.os-icon {
            color: #fff;
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0px;
            margin-right: 3px;
          }
          span {
            display: inline-block;
            vertical-align: middle; 
            font-size: $font-size-base * 0.8;
          }
        }
      }
    }
  }
}

.element-box {
  .os-tabs-controls {
    margin-left: -2rem;
    margin-right: -2rem;
    .nav {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

@media (max-width: 767px){
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    .nav + .nav {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .os-tabs-w {
    .os-tabs-controls {
      margin-bottom: 1rem;
    }
    .os-tabs-complex {
      .nav-item {
        flex: 1;
        .nav-link {
          border-right: 0px;
          border-top: 1px solid rgba(0,0,0,0.05);
          text-align: center;
          padding: 15px;
        }
        &:first-child {
          .nav-link {
            border-top: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (max-width: 1250px) {
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}