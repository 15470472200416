.scan_wrap {
  padding: 1.3rem;
  h3 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 36px;
    strong {
      line-height: 40px;
    }
  }
  h5 {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 36px;
  }
  a {
    display: inline-block;
    padding-top: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .scan_messageBody {
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
