.layout,
.layout_full {
  padding: 35px 55px;
}

.layout {
  max-width: 900px;
}

.header {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 2rem;
}

.breadcrumb {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 0 10px 0;
  color: rgb(107, 119, 140);

  li {
    a {
      color: inherit;
    }
  }
}
