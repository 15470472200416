.container {
  min-height: 100vh;
  padding: 0.6rem;
  background: #fff;
}
.item {
  display: flex;
  padding: 0 0 0.6rem 0;
  .img {
    width: 110px;
    height: 153px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .cnt {
    flex-grow: 1;
    padding-left: 0.6rem;
    .title {
      height: 30px;
      margin: 0.3rem 0;
      background-color: #f5f5f5;
    }
    .txt {
      height: 15px;
      margin: 0.6rem 0;
      background-color: #f5f5f5;
    }
    .txt2 {
      height: 12px;
      margin: 0.6rem 0 0.3rem 0;
      background-color: #f5f5f5;
    }
    .txt3 {
      height: 20px;
      margin: 0.3rem 0;
      background-color: #f5f5f5;
    }
  }
}
