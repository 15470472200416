.prview {
  position: absolute;
  bottom: 3rem;
  right: 2rem;
  margin: auto 0 auto auto;
  border: 12px solid #222;
  background-color: #222;
  border-radius: 40px;
  .ic_badge {
    position: absolute;
    top: -40px;
    right: 10px;
    width: 40px;
    padding: 8px 0;
    cursor: pointer;
    text-align: center;
    color: #fff;
    background-color: #000;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    z-index: 10;
  }
  .overlay {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0.775rem;
    top: 2.5rem;
    background-color: transparent;
  }
  .prview_inner {
    overflow: hidden;
    width: 300px;
    height: 600px;
    max-width: 100%;
    // padding: 0.8rem;
    word-break: break-all;
    border-radius: 34px;
    background-color: #fff;
  }
}
