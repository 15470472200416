.min-full-height {
  min-height: 100vh;
}

.fs-loading-wrap {
  position: fixed;
  z-index: 999;
  background: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.fs-loading-wrap img {
  width: 100px;
  height: 100px;
}

.fs-loading-wrap.removed  {
  visibility: hidden;
  opacity: 0;
}

.table-secondary td {
  opacity: 0.5 !important;
}

.lf-search {
  padding: 1rem;
  border: 0;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(69, 101, 173, 0.1);
}

/*
.dashboard-icon {
  padding: 15px 5px;
  background-color: #fff;
  width: 155px;
  text-align: center;
  display: block;
  color: #555;
  margin-right: 10px;
  margin-bottom: 10px;
  
  i {
    font-size: 30px;
  }

  span {
    display: block;
    margin-top: 10px;
  }
}

.dashboard-icon:hover {
  color: #1c4cc3;
  text-decoration: none;
}
*/

.dropdown-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: auto;

  li {
    padding: 7px 15px;
    word-break: break-all;
    // border-bottom: 1px solid #f1f1f1;
    color: #1d1e1f;
    cursor: pointer;
    user-select: none
  }

  li:hover {
    background: #f8f9fa;
  }

  li.selected {
    background: #3498db;
    color: #fff;

    .dropdown-dotted-line {
      border-left-color: #2980b9;
    }
  }

  .dropdown-dotted-line {
    position: absolute;
    display: block;
    width: 1px; top: 0px; bottom: 0px;
    background: inherit;
    border-left: 1px;
    border-left-color: #eee;
    border-left-style: solid;
  }
}

.element-search {
  position: relative;
  &:before {
    @include osfont();
    position: absolute;
    left: 15px;
    top: 48%;
    transform: translateY(-50%);
    font-size: 20px;
    content: "\e92c";
    color: rgba(0,0,0,0.2);
  }
  input {
    border: none;
    box-shadow: none;
    background-color: #f1f1f1;
    border-radius: 30px;
    padding: 10px 15px 10px 50px;
    display: block;
    width: 100%;
    outline: none;
    &::placeholder {
      color: rgba(0,0,0,0.3);
    }
  }
}
